* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-color: #5e283f;
  --secondary-color: #9f496e;
  --primary-light-color: #ebdee6;
  --page-bg-color: #ffe6f5;
  --dark-color: #323546;
  --light-color: #ffffff;
  --light-grey-color: #c2c2c2;
  --error: rgb(207, 74, 74);
  --dark-grey-color: rgb(77, 77, 77);
  --font-weight-normal: 700;
  --font-weight-small: 500;
  --black: #17171a;
  --white: #f8f8f8;
  --sidePanelExpand: 250px;
  --sidePanelCollapse: 80px;
  --sidePanelBackgroundColor: rgb(46, 46, 43);
  --topNavBarHeight: 60px;

  /* new ui theme */

  --color-grey-0: #292d34;
  --color-grey-1: #7c828d;
  --color-grey-2: #b9bec7;
  --color-grey-3: #e9ebf0;
  --color-grey-4: #fafbfc;
  --color-grey-5: #2a2e34;
  --color-white: #fff;
  --color-black: #000;
  --color-pink: #eb3dae;
  --color-pink-dark: #de1798;
  --color-red: #fd7171;
  --color-blue: #5bc5fa;
  --color-cyan: #49ccf9;
  --color-green: #6bc950;
  --color-teal: #5cc0bf;
  --color-teal-light: #8afcde;
  --color-yellow: #f7ce51;
  --color-yellow-dark: #f5cd51;
  --color-purple: #7b42d1;
  --color-purple-dark: #8041d0;
  --color-background: #fafbfc;
  --color-brand-facebook: #3b5998;
  --color-brand-instagram: #c32aa3;
  --color-brand-linkedin: #0077b5;
  --color-brand-twitter: #1da1f2;
  --color-brand-youtube: red;
  --color-success: #6bc950;
  --color-error: #e04f44;
  --bg-main: #fafbfc;
  --bg-secondary: #fff;
  --color-brand: #7b68ee;
  --theme-main-color: #7b68ee;
  --theme-main-color-dark: #5f48ea;
  --theme-main-color-light: #dfdbfb;
  --color-table-header: #f0f1f3e6;
  --color-card-1: #d3d3d3;
  --color-card-2: #ff7fab;
  --color-card-3: #2ecd6f;
  --color-card-4: #f9d900;
  --color-card-5: #02BCD4;
  --color-card-6: #ff7800;
  --color-card-7: #bf55ec;
  --color-card-8: #e50000;
  --color-card-9: #0231E8;
  --font: -apple-system, BlinkMacSystemFont, Segoe UI, roboto, Helvetica Neue, helvetica, arial, sans-serif;
}

body {
  margin: 0 !important;
  background-color: var(--bg-main) !important;
  font-family: var(--font) !important;
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

.primaryText {
  color: var(--primary-color);
}

.greyText {
  color: var(--light-grey-color);
}

/* This depends on sidePanelExpand and sidePanelCollapse styles in sidePanel component */
.outerPage {
  background-color: #fafbfc;
  padding: 15px;
  overflow-y: scroll;
  height: calc(100vh - var(--topNavBarHeight));
  width: 100%;
}

/* This depends on sidePanelExpand and sidePanelCollapse styles in sidePanel component */
.innerPage {
  width: 100%;
  min-height: 90vh;
  /* background-color: var(--light-color); */
  border-radius: 15px;
}

.innerPage1 {
  width: 100%;
  min-height: 90vh;
  background-color: var(--light-color);
  border-radius: 15px;
}
/* li {
  list-style-type: none;
} */

.fillBtn {
  background-color: var(--theme-main-color) !important;
  color: var(--light-color) !important;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5) !important;
  border: 2px solid var(--theme-main-color) !important;
  letter-spacing: 0.8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  height: 32px;
}

.fillBtn:hover {
  background-color: var(--theme-main-color) !important;
  color: var(--light-color) !important;
  border: 2px solid var(--theme-main-color) !important;
  transform: scale(1.03);
}

.outLineBtn {
  box-shadow: 0 3px 10px -3px #b9bec7;
  background-color: #b9bec7 !important;
  letter-spacing: 0.8px;
  height: 32px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  color: #fff !important;
  border: 0 !important;
  border-radius: 8px;
  font-size: 14px !important;
  box-shadow: 0 3px 10px -3px #b9bec7;
}

.outLineBtn:hover {
  background-color: #b9bec7 !important;

}

.modal-labels {
  font-size: 14px;
  font-weight: 500;
  line-height: 11px;
  display: block;
  color: #292d34;
  padding: 0 0 8px 2px;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

/* .form-control:focus,
.form-control:active {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:active,
.form-control:focus {
  border: 1px solid var(--primary-color) !important;
} */

.form-control {
  transition: border-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  width: 100% !important;
  border: 1px solid #d6d9de !important;
  box-sizing: border-box !important;
  border-radius: 9px !important;
  padding: 0 20px 0 10px !important;
  height: 40px !important;
}

textarea.form-control {
  padding: 10px 20px 0 10px !important;
}

/* tabs styling start */
.nav-tabs {
  color: var(--secondary-color);
}

.nav-link {
  /* color: var(--dark-grey-color) !important;
  font-size: 16px;
  font-weight: 700 !important;
  letter-spacing: 0.8px !important; */
  color: #7c828d !important;
  border: none;
  margin-bottom: 0;
  font-size: 14px;
}

.nav-link:hover {
  color: var(--dark-grey-color) !important;
  cursor: pointer !important;
}

.nav-link.active {
  color: var(--color-grey-0) !important;
}

.table-spinner {
  height: 50px !important;
  width: 50px !important;
  color: var(--theme-main-color)
}

.error {
  color: red;
}

/* tabs styling end */

@media screen and (max-width: 768px) {
  .outerPage {
    padding: 08px;
  }
}

.spinner {
  width: 70px;
  text-align: center;
}

.spinner>div {
  width: 12px;
  height: 12px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {

  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.badge-icon {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-main-color);
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  margin-top: -36px;
  margin-left: 20px;
}

/* .login {
  display: flex;
  justify-content: center;
  height: calc(100% - 50vh);
  width: 100%;
  top: 200vh;
  left: 0;
  overflow: hidden; 
  background-image: url("./Assets/Images/wave.svg");
  background-size: cover;
} */

.login-bg{
  position: absolute;
  height: calc(100% - 50vh);
  width: 100%;
  top: 50vh;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  background-image: url("./Assets/Images/wave.svg");
  background-size: cover;
  z-index: -1;
}

.dropdown,
.dropstart,
.dropdown-toggle {
  padding: 2px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  color: transparent !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  border: none;
}

.dropdown-toggle::before {
  border: none;
  margin: 0;
}

.dropdown-toggle.show {
  background-color: var(--color-grey-3);
  padding: 5px 8px;
}

.dropdown-item:focus,
.dropdown-item:active {
  background-color: var(--color-grey-3);
}

.dropdown-menu {
  box-shadow: 0 1px 10px #00000040;
  background: #fff;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
}
.dropdown-item {
  border-radius: 5px;
  color: #292d34;
  text-decoration: none;
  padding: 5px;
}