.tableContainer {
  overflow-x: auto;
  /* -ms-overflow-style: none;  Internet Explorer 10+ */
  /* scrollbar-width: none;  Firefox */
}

/* .tableContainer::-webkit-scrollbar { 
    display: none;  Safari and Chrome
} */

table {
  width: 100%;
  /* border-left: 5px solid var(--secondary-color); */
}

td,
th {
  /* border-bottom: 1px solid var(--light-grey-color) !important; */
  padding: 8px;
  /* border-bottom: #f7f8f9e6;
    border: 1px solid #e9ebf0; */
  border-bottom: 1px solid #e9ebf0 !important;
}

td {
  text-align: center !important;
}

th {
  background-color: #f0f1f3e6;
  color: var(--color-grey-0);
  font-size: 12px;
  font-weight: 500;
  text-align: center !important;
  height: 28px !important;
}

tr:hover {
  background-color: #e2e2e2;
}

.actionDelBtn,
.actionEditBtn {
  outline: none !important;
  border: none !important;
  padding: 0px;
}

.actionEditBtn {
  color: var(--theme-main-color) !important;
}

.actionDelBtn {
  color: var(--error) !important;
}

.actionCell {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.switch:checked {
  background-color: var(--theme-main-color) !important;
  border-color: var(--theme-main-color) !important;
  box-shadow: none !important;
}

.switch:hover {
  cursor: pointer;
}

