.change-plan button{
    width: fit-content;
    padding: 20px;
    border: 0;
    background-color: #9f496e;
    color: #ffff;
    font-weight: 700;
}

.plan-form button{
    width: 100%;
    padding: 10px;
    border: 0;
    background-color: #9f496e;
    color: #ffff;
    font-weight: 700;
}