.send-comment .comment-input {
  height: 30px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  /* color: $color-grey-0; */
  /* // background-image: url("../svg/comment.svg"); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
}

.chat-input {
  border-top: 1px solid #b9bec7;
  position: sticky;
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.07);
  padding: 10px;
}

.in-chat {
  background: #f9f9f9;
  border: 1px solid #e9ebf0;
  min-height: 70px;
  border-radius: 8px;
  word-break: break-all;
  width: 100%;
}

.client-icon {
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  border: 1px dashed;
  width: 40px;
  height: 40px;
  display: flex;
  margin: 0 10px;
}

.sm-text {
  font-size: 11px;
  font-weight: 400;
  color: #777;
}

.read-chat{
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
}

::file-selector-button{
  display: none;
}