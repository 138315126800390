.alert-modal{
    display: flex;
    justify-content: center;
    padding: 1rem;
    height: 5rem;
    color: #FFFFFF;  
}

.alert-message{
    text-align: center;
    font-size: 20px;
    min-height: 5rem;
    margin-top: 2rem;
} 

.alert-modal-success{
    background-color: #52B879;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.alert-modal-error{
    background-color: #ff5664;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}