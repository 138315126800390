.main {
  height: 100vh;
  width: 100vw;
}

.wrapper {
    display: flex;
    align-items: stretch;
}

.sidebarOpen{
  position: fixed;
  background-color: var(--sidePanelBackgroundColor);
  width: var(--sidePanelExpand);
  height: 100%;
}

.app-logo {
  min-height: var(--topNavBarHeight);
  background-color: #fff;
  border-bottom: 1px solid var(--white);
  display: flex;
  justify-content:center;
  align-items: center;
}

.content {
  height: 49px;
  width: 100vw;
  overflow: auto; 
  background-color: var(--light-color);
  margin-left: 62px;
  /* -webkit-box-shadow: 0 10px 10px -10px #000000;
   -moz-box-shadow: 0 10px 10px -10px #000000;
        box-shadow: 0 10px 10px -10px #000000; */
  z-index: 2;
  border-bottom: 1px solid #e9ebf0;
}

.sidebarOpen .sidebar-links{
  padding: 12px 8px;
  margin: 8px 0px;
}

.sidebarOpen .activeLink .sidebar-links,
.sidebarClose .activeLink .sidebar-links,
.sidebarClose .sidebar-links:hover,
.sidebarOpen .sidebar-links:hover{
  background-color: var(--theme-main-color);
  opacity: 0.9;
  color: #fff;
}

.logout-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
}

.brandName {
  color: #ffffff;
  font-weight: 900;
  font-size: 18px;
}

.sidebarOpen .sidebar-links .sidebar-links-name{
  color: #ffffff;
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.sidebarOpen .activeLink .sidePanelIcons,
.sidebarClose .activeLink .sidePanelIcons,
.sidebarOpen .activeLink .sidebar-links-name,
.sidebarOpen .sidebar-links:hover .sidePanelIcons,
.sidebarClose .sidebar-links:hover .sidePanelIcons, 
.sidebarOpen .sidebar-links:hover .sidebar-links-name{
  color: #ffffff !important;
}

.sidebarClose{
  position: fixed;
  /* background-color: var(--sidePanelBackgroundColor); */
  background-color: var(--color-white);
  border-right: 1px solid #e9ebf0;
  /* width: var(--sidePanelCollapse); */
  width:62px;
  height: 100%;
  box-sizing: border-box;
  z-index: 3;
}

.activeContent{
  /* height: var(--topNavBarHeight); */
  height: 49px;
  width: 100vw;
  margin-left: var(--sidePanelCollapse);
  overflow: auto; 
  background: var(--light-color);
  /* -webkit-box-shadow: 0 10px 10px -10px #000000;
   -moz-box-shadow: 0 10px 10px -10px #000000; */
        /* box-shadow: 0 10px 10px -10px #000000; */
        border-bottom: 1px solid #e9ebf0;
  z-index: 2;
}

.sidebarClose .sidebar-links-name{
  display: none;
}

.sidebarClose .sidebar-links{
  margin: 12px;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
}

.profilePicture {
  height: 40px;
  width: 40px;
}

.logo {
  height: 55px;
  width: 60px;
}

.sidePanelCollapse {
  width: calc(100vw - var(--sidePanelCollapse));
  position: relative;
  left: 62px;
  height: calc(100vh - var(--topNavBarHeight));
}

.sidePanelExpand {
  width: calc(100vw - var(--sidePanelExpand));
  position: relative;
  left: var(--sidePanelExpand);
  height: calc(100vh - var(--topNavBarHeight));
}

.sidePanelToggleBtn {
  border: none;
  border-radius: 05px;
  background: var(--light-color);
  color: var(--primary-color);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.sidebarOpen .sidePanelIcons {
  color: var(--color-grey-0);
  font-size: 25px !important;
}

.sidebarClose .sidePanelIcons {
  color: var(--color-grey-0);
  margin: 5px 0px;
  font-size: 24px !important;
}

.userName {
  font-size: 16px;
}


@media (max-width: 768px) {
  .sidebarOpen{
   display: none;
  }

  .content{
    margin-left: 0px;
  }

  .profilePicture {
    height: 35px;
    width: 35px;
  }

  /* added z-index and made sidepanel to display over, so page width is not dependent on sidepanel width */
  .sidePanelCollapse {
    position: relative;
    left: 0px;
    /* left: var(--sidePanelCollapse); */
    /* width: calc(100vw - var(--sidePanelCollapse)); */
    width: 100vw;
  }

  .sidePanelExpand {
      position: relative;
      left: 0px;
      width: 100vw;
  }

  .userName {
    font-size: 14px;
  }

  .activeContent{
    width: 100vw;
    margin-left: 0px;
  }

  .activeContent .sidePanelToggleBtn {
    margin-left: 80px;
  }

  .content .sidePanelToggleBtn {
    margin-left: 0px;
  }

  .logo {
    height: 50px;
    width: 55px;
  }
  

}


