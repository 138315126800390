.tableContainer {
  overflow-x: auto;
  /* -ms-overflow-style: none;  Internet Explorer 10+ */
  /* scrollbar-width: none;  Firefox */
}

table {
  width: 100%;
  /* border-left: 5px solid var(--secondary-color); */
}

td,
th {
  /* border-bottom: 1px solid var(--light-grey-color) !important; */
  padding: 8px;
  border-bottom: 1px solid #e9ebf0!important;
  /* border: 1px solid #e9ebf0 !important; */
}

td {
  text-align: center !important;
}

th {
  background-color: #f0f1f3e6;
  color: var(--color-grey-0);
  font-size: 12px;
  font-weight: 500;
  text-align: center !important;
  height: 28px !important;
}

td {
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;
}

tr:hover {
  background-color: #e2e2e2;
}

.actionDelBtn,
.actionEditBtn {
  outline: none !important;
  border: none !important;
  padding: 0px;
}

.actionEditBtn {
  color: var(--theme-main-color) !important;
}

.actionDelBtn {
  color: var(--error) !important;
}

.actionCell {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.paginationBtnContainer {
  display: flex;
  align-items: center;
}

.direction {
  padding: 10px;
  border: 0.5px solid black;
  color: var(--theme-main-color);
  border-radius: 4px;
}

.direction:hover {
  color: var(--theme-main-color);
}

.activePage {
  background-color: var(--theme-main-color);
  border-radius: 4px;
  padding: 10px;
  color: #ffffff;
  font-weight: 900;
}

.eachPage {
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 700;
}

.select {
  padding: 8px;
  width: 200px !important;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}

.downArrow {
  border: none;
  background-color: var(--page-bg-color);
  border-radius: 2px;
}

.downArrow:hover {
  background-color: var(--light-grey-color);
}