.image {
  background: url("../../Assets/Images/bg-img.jpg") no-repeat;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}


.loginBox {
  /* height: 470px; */
  /* background-color: var(--white); */
  margin: 0 auto;
  /* margin-top: 120px; */
  /* border-radius: 10px;
  box-shadow:  0px 0px 20px 0px #000; */
  width: 480px !important;
  background: #fff;
  box-shadow: 0 24px 64px #26214a1a;
  border-radius: 12px;
  padding: 30px 60px;
  /* position: relative; */
}

.headingText {
  font-weight: 700;
  font-size: 32px;
  font-weight: 700;
  line-height: 46px;
  color: #292d34;
  text-align: center;
  margin-bottom: 20px;
}

.linkText {
  color: var(--secondary-color);
  font-weight: 600;
  background-color: transparent;
  outline: none;
  border: none
}

.linkText:hover {
  color: var(--secondary-color);
  text-decoration: underline !important;
}

.textField {
  /* width: 100%;
  height: 50px;
  outline: none;
  border: none;
  background-color: rgb(223, 215, 215);
  padding: 0px 40px; */
  transition: border-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  border: 1px solid #d6d9de;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 0 20px 0 40px;
  height: 40px;
}

.textFieldError {
  border: 1px solid red;
}

/* 
.textField:focus {
  background-color: rgb(194, 193, 193);
} */

.fieldIcon {
  position: absolute;
  left: 05px;
  margin: auto;
  line-height: 50px;
  color: #b9bec7;
}

.pwdButton {
  outline: none !important;
  border: none !important;
  padding: 0px;
  color: var(--theme-main-color) !important;
}

.pwdIcon {
  position: absolute;
  right: 0px;
  margin: auto;
  line-height: 50px;
  color: var(--theme-main-color);
}

.submitButton {
  font-weight: 700 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
  min-height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 0;
  border-radius: 9px;
  width: 100%;
  margin-bottom: 22px;
  box-shadow: 0 10px 25px #7b68ee80;
  background-color: #7b68ee;
  font-size: 14px;
  font-weight: 800;
  line-height: 1;
  color: #fff;
}

.brandName {
  margin-top: 0.4rem;
  font-weight: bold;
  margin: 2rem;
}

.input-group {
  align-items: center !important;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}
