.container{
  width: 90%;
  margin: auto;
}


@media screen and (max-width:768px) {
  .container{
    width: 100%;

  }
}