@media screen and (min-width: 1024px) {
  .formArray {
    display: flex;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .formArray {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 376px) {
  .formArray {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
